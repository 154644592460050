<template>
  <div class="turnstile-container">
    <vue-turnstile
      :site-key="siteKey"
      v-model="token"
      @error="handleError"
      @expired="handleExpired"
      ref="turnstileRef"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, watch, onMounted, defineEmits, defineProps, defineExpose } from 'vue';
import VueTurnstile from 'vue-turnstile';
import { ElMessage } from 'element-plus';

const props = defineProps({
  onSuccess: {
    type: Function,
    required: false,
    default: () => {
      // 空函数
      return undefined;
    }
  }
});

const emit = defineEmits(['token-change']);

// Cloudflare Turnstile site key
const siteKey = '0x4AAAAAABAQWW1MK2ukyat6';
const token = ref('');
const turnstileRef = ref<InstanceType<typeof VueTurnstile> | null>(null);

// 监听token变化
watch(token, (newToken) => {
  if (newToken) {
    emit('token-change', newToken);
    if (props.onSuccess) {
      props.onSuccess(newToken);
    }
  }
});

// 处理错误
const handleError = (errorCode: string) => {
  console.error('Turnstile error:', errorCode);
  ElMessage.error('验证失败，请重试');
  resetTurnstile();
};

// 处理过期
const handleExpired = () => {
  console.warn('Turnstile token expired');
  resetTurnstile();
};

// 重置Turnstile
const resetTurnstile = () => {
  if (turnstileRef.value) {
    turnstileRef.value.reset();
  }
};

// 导出重置方法，供父组件调用
defineExpose({
  reset: resetTurnstile,
  getToken: () => token.value
});
</script>

<style scoped>
.turnstile-container {
  margin: 10px 0;
  display: flex;
  justify-content: center;
}
</style> 