<template>
  <div class="login-container">
    <div class="loading-screen" v-if="!logoLoaded">
      <div class="loading-spinner"></div>
      <p>加载中...</p>
    </div>

    <div class="logo-container" :class="{ 'animation-complete': animationComplete }" v-show="logoLoaded">
      <img 
        src="../assets/account.png" 
        alt="Logo" 
        class="logo" 
        ref="logoRef"
        @load="handleLogoLoaded" 
        @error="handleLogoError"
      />
      <h1 class="system-title" :class="{ 'show': animationComplete }">E时代 统一认证中心</h1>
    </div>

    <div class="login-box" :class="{ 'show': animationComplete }" v-show="logoLoaded">
      <div class="login-header">
        <div class="content-wrapper" :class="{ 'show': animationComplete }">
          <h2>登 录</h2>
          <div class="login-tabs">
            <span
              :class="['tab', currentMethod === 'account' ? 'active' : '']"
              @click="currentMethod = 'account'"
            >
              账号密码
            </span>
            <span
              :class="['tab', currentMethod === 'email' ? 'active' : '']"
              @click="currentMethod = 'email'"
            >
              邮箱验证码
            </span>
          </div>
        </div>
      </div>

      <div class="login-content" :class="{ 'show': animationComplete }" v-loading="loading">
        <transition name="fade" mode="out-in">
          <div v-if="currentMethod === 'account'" key="account" class="login-form">
            <el-form ref="accountFormRef" :model="formData" :rules="rules" @keypress.enter="handleSubmit">
              <el-form-item prop="username" label="用户名/邮箱">
                <el-input 
                  v-model="formData.username" 
                  placeholder="用户名或邮箱"
                  clearable
                  @clear="handleClearUsername"
                >
                  <template #prefix>
                    <el-icon><User /></el-icon>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item prop="password" label="密码">
                <el-input 
                  v-model="formData.password" 
                  :type="showPassword ? 'text' : 'password'"
                  placeholder="密码"
                >
                  <template #prefix>
                    <el-icon><Lock /></el-icon>
                  </template>
                  <template #suffix>
                    <el-icon class="cursor-pointer" @click="showPassword = !showPassword">
                      <Hide v-if="showPassword" />
                      <View v-else />
                    </el-icon>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item label="">
                <TurnstileComponent ref="accountTurnstileRef" />
              </el-form-item>
            </el-form>
          </div>

          <div v-else-if="currentMethod === 'email'" key="email" class="login-form">
            <el-form ref="emailFormRef" :model="emailFormData" :rules="emailRules" @keypress.enter="handleSubmit">
              <el-form-item prop="email" label="邮箱">
                <el-input 
                  v-model="emailFormData.email"
                  placeholder="邮箱"
                  clearable
                  @clear="handleClearEmail"
                >
                  <template #prefix>
                    <el-icon><Message /></el-icon>
                  </template>
                </el-input>
              </el-form-item>
              
              <el-form-item prop="code" label="验证码">
                <div class="verification-code">
                  <el-input 
                    v-model="emailFormData.code" 
                    :maxlength="6"
                    placeholder="邮箱验证码"
                  >
                    <template #prefix>
                      <el-icon><Key /></el-icon>
                    </template>
                  </el-input>
                  <el-button
                    :disabled="countdown > 0"
                    :loading="sendingCode"
                    @click="showTurnstileDialog"
                  >
                    {{ countdown > 0 ? `${countdown}秒后重试` : '获取验证码' }}
                  </el-button>
                </div>
              </el-form-item>
              <el-form-item label="">
                <TurnstileComponent ref="emailTurnstileRef" />
              </el-form-item>
            </el-form>
          </div>

        </transition>

        <div class="action-buttons">
          <el-button type="primary" @click="handleSubmit" :loading="loading" style="width: 100%">
            登录
          </el-button>
          <div class="helper-links">
            <el-button text @click="goToRegister">
              没有账号？立即注册
            </el-button>
            <el-button text @click="showPasswordRecoveryTip">忘记密码？</el-button>
          </div>
        </div>

        <div class="third-party-login">
          <div class="divider">
            <span>其他登录方式</span>
          </div>
          <div class="third-party-icons">
            <t-icon name="logo-qq" @click="handleQQLogin" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted, defineEmits, onBeforeMount, watch, h } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/store/user';
import { useSharedStore } from '@/store/shared';
import request from '@/utils/request';
import { User, Lock, Message, Key, View, Hide } from '@element-plus/icons-vue';
import type { FormInstance } from 'element-plus';
import { config } from '@/config'
import TurnstileComponent from '@/components/TurnstileComponent.vue';

declare const QC: Window['QC']

const router = useRouter();
const userStore = useUserStore();
const sharedStore = useSharedStore();

const currentMethod = ref('account');

const formData = reactive({
  username: sharedStore.email || '',
  password: '',
});

const emailFormData = reactive({
  email: sharedStore.email || '',
  code: '',
});

const loading = ref(false);
const sendingCode = ref(false);

const rules = {
  username: [
    { required: true, message: '请输入用户名或邮箱', trigger: 'blur' },
    { 
      validator: (rule: any, value: string, callback: any) => {
        if (value.includes('@')) {
          // 如果是邮箱格式，使用邮箱验证
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(value)) {
            callback(new Error('请输入正确的邮箱格式'));
          }
        } else if (value.length < 3) {
          callback(new Error('用户名建议不少于3个字符'));
        }
        callback();
      },
      trigger: 'blur'
    }
  ],
  password: [
    { required: true, message: '请输入密码', trigger: 'blur' },
    { min: 6, max: 20, message: '密码长度为6-20位', trigger: 'blur' },
  ],
};

const emailRules = {
  email: [
    { required: true, message: '请输入邮箱', trigger: 'blur' },
    { type: 'email', message: '请输入正确的邮箱格式', trigger: 'blur' },
  ],
  code: [
    { required: true, message: '请输入验证码', trigger: 'blur' },
    { len: 6, message: '验证码长度为6位', trigger: 'blur' },
  ],
};

const countdown = ref(0);
const startCountdown = () => {
  countdown.value = 60;
  const timer = setInterval(() => {
    countdown.value--;
    if (countdown.value <= 0) {
      clearInterval(timer);
    }
  }, 1000);
};

const sendVerificationCode = async () => {
  try {
    if (!emailFormRef.value) return;
    await emailFormRef.value.validateField('email');
    
    // 获取图形验证码
    const captcha = await userStore.getCaptcha();
    if (!captcha?.image) {
      throw new Error('获取验证码图片失败');
    }
    
    // 显示图形验证码对话框
    ElMessageBox.prompt('请输入图形验证码', '验证', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      inputPattern: /.+/,
      inputErrorMessage: '验证码不能为空',
      dangerouslyUseHTMLString: true,
      message: `<div style="text-align:center"><img src="${captcha.image}" style="max-width:100%;margin-bottom:10px;" /></div>`,
    }).then(async ({ value }) => {
      await userStore.sendEmailCode({
        email: emailFormData.email,
        captchaCode: value,
        captchaId: userStore.captchaId
      });
      
      ElMessage.success('验证码已发送');
      startCountdown();
    }).catch(() => {
      // 用户取消操作
    });
    
  } catch (error: any) {
    ElMessage.error(error.message || '验证码发送失败');
  }
};

// 显示Turnstile验证对话框
const showTurnstileDialog = async () => {
  try {
    if (!emailFormRef.value) return;
    await emailFormRef.value.validateField('email');
    
    // 获取Turnstile验证令牌
    const token = emailTurnstileRef.value?.getToken();
    if (!token) {
      ElMessage.warning('请完成人机验证');
      return;
    }
    
    sendingCode.value = true;
    try {
      await userStore.sendEmailCodeWithTurnstile({
        email: emailFormData.email,
        turnstileToken: token
      });
      
      ElMessage.success('验证码已发送');
      startCountdown();
      // 重置验证组件
      emailTurnstileRef.value?.reset();
    } catch (error: any) {
      ElMessage.error(error.message || '发送验证码失败');
    } finally {
      sendingCode.value = false;
    }
  } catch (error: any) {
    ElMessage.error(error.message || '验证失败');
  }
};

const qrcodeUrl = ref('');
const refreshQrcode = async () => {
  try {
    qrcodeUrl.value = 'https://example.com/qrcode';
  } catch (error) {
    ElMessage.error('二维码获取失败');
  }
};

const thirdPartyLogin = (platform: string) => {
  if (platform === 'qq') {
    console.log('QQ登录');
    // 处理QQ登录逻辑
  }
};

const goToRegister = () => {
  // 保留当前输入的邮箱信息
  if (currentMethod.value === 'account' && formData.username && formData.username.includes('@')) {
    sharedStore.setEmail(formData.username);
  } else if (currentMethod.value === 'email' && emailFormData.email) {
    sharedStore.setEmail(emailFormData.email);
  }
  router.push('/register');
};

const accountFormRef = ref<FormInstance>();
const emailFormRef = ref<FormInstance>();
const accountTurnstileRef = ref<InstanceType<typeof TurnstileComponent>>();
const emailTurnstileRef = ref<InstanceType<typeof TurnstileComponent>>();

const handleSubmit = async () => {
  try {
    if (loading.value) return;
    
    if (currentMethod.value === 'account') {
      if (!accountFormRef.value) return;
      await accountFormRef.value.validate();
      
      // 获取Turnstile验证令牌
      const token = accountTurnstileRef.value?.getToken();
      if (!token) {
        ElMessage.warning('请完成人机验证');
        return;
      }
      
      loading.value = true;
      try {
        await userStore.login({
          username: formData.username,
          password: formData.password,
          turnstileToken: token
        });

        // 登录成功后清空密码，但保留邮箱
        formData.password = '';
        
        ElMessage.success('登录成功');
        const redirect = (router.currentRoute.value.query.redirect as string) || '/profile';
        if (redirect.startsWith('/login')) {
          router.push('/profile');
        } else {
          router.push(redirect);
        }
      } catch (error: any) {
        ElMessage.error(error.message || '登录失败');
        // 重置验证组件
        accountTurnstileRef.value?.reset();
      } finally {
        loading.value = false;
      }
      
    } else if (currentMethod.value === 'email') {
      if (!emailFormRef.value) return;
      await emailFormRef.value.validate();
      
      // 获取Turnstile验证令牌
      const token = emailTurnstileRef.value?.getToken();
      if (!token) {
        ElMessage.warning('请完成人机验证');
        return;
      }
      
      loading.value = true;
      try {
        await userStore.loginWithEmail({
          email: emailFormData.email,
          code: emailFormData.code,
          turnstileToken: token
        });

        // 登录成功后清空验证码，但保留邮箱
        emailFormData.code = '';
        
        ElMessage.success('登录成功');
        const redirect = (router.currentRoute.value.query.redirect as string) || '/profile';
        if (redirect.startsWith('/login')) {
          router.push('/profile');
        } else {
          router.push(redirect);
        }
      } catch (error: any) {
        ElMessage.error(error.message || '登录失败');
        // 重置验证组件
        emailTurnstileRef.value?.reset();
      } finally {
        loading.value = false;
      }
    }
  } catch (error: any) {
    ElMessage.error({
      message: error.message || '登录失败',
      grouping: true
    });
  }
};

const animationComplete = ref(false);

const emit = defineEmits(['animation-complete'])

const logoLoaded = ref(false);
const logoRef = ref<HTMLImageElement | null>(null);
const logoLoadRetries = ref(0);
const MAX_RETRIES = 3;

const handleLogoLoaded = () => {
  logoLoaded.value = true;
  startLoginAnimation();
};

const handleLogoError = () => {
  logoLoadRetries.value++;
  if (logoLoadRetries.value <= MAX_RETRIES) {
    ElMessage.warning('加载失败，正在重试...');
    // 强制重新加载
    setTimeout(() => {
      if (logoRef.value) {
        // 通过修改DOM元素的src属性来强制重新加载
        const timestamp = Date.now();
        logoRef.value.src = `../assets/account.png?t=${timestamp}`;
      }
    }, 500);
  } else {
    logoLoaded.value = true;
    ElMessage.error('加载失败，请检查图片文件');
    startLoginAnimation();
  }
};

const startLoginAnimation = () => {
  setTimeout(() => {
    animationComplete.value = true;
    setTimeout(() => {
      emit('animation-complete');
    }, 1000);
  }, 600);
};

// 初始状态下，图片未加载完成，显示加载屏幕
onMounted(() => {
  // 添加超时处理，避免用户长时间等待
  setTimeout(() => {
    if (!logoLoaded.value) {
      logoLoaded.value = true;
      ElMessage.warning('图片加载超时，已为您显示页面');
      startLoginAnimation();
    }
  }, 2000);
});

const showPassword = ref(false);

onBeforeMount(async () => {
  if (userStore.token) {
    try {
      await userStore.checkAuth()
      const redirect = (router.currentRoute.value.query.redirect as string) || '/profile'
      router.replace(redirect)
    } catch (error) {
      // token 无效，清除登录状态
      userStore.logout()
    }
  }
})

const showPasswordRecoveryTip = () => {
  ElMessageBox.alert(
    '请使用邮箱验证码登录后修改密码，或重新注册新账号，如有特别情况，请联系 accountrecovery@qifalab.com',
    '找回密码提示',
    {
      confirmButtonText: '知道了',
      type: 'info'
    }
  ).catch(() => {
    // 用户点击关闭按钮时的处理
    // 这里可以为空，但需要catch来防止报错
  })
}

const handleQQLogin = () => {
  // 生成随机state用于防止CSRF攻击
  const state = Math.random().toString(36).substring(7);
  localStorage.setItem('qq_login_state', state);
  
  // 使用QQ登录SDK
  QC.Login.showPopup({
    appId: config.qq.appId,
    redirectURI: config.qq.redirectUri,
    state: state
  });

  // 监听消息
  window.addEventListener('message', async (event) => {
    try {
      if (event.data.type === 'qq-login') {
        const { accessToken, state: returnState } = event.data;
        
        // 验证state
        const savedState = localStorage.getItem('qq_login_state');
        if (savedState !== returnState) {
          throw new Error('非法的登录请求');
        }
        
        // 清除state
        localStorage.removeItem('qq_login_state');
        
        // 调用登录接口
        await userStore.loginWithQQ({ accessToken, state: returnState });
        
        ElMessage.success('登录成功');
        const redirect = (router.currentRoute.value.query.redirect as string) || '/profile';
        router.push(redirect);
      }
    } catch (error: any) {
      ElMessage.error(error.message || 'QQ登录失败');
    }
  }, { once: true });
};

// 监听username变化，如果是邮箱格式则同步到email字段和共享存储
watch(() => formData.username, (newValue) => {
  if (newValue && newValue.includes('@')) {
    emailFormData.email = newValue;
    sharedStore.setEmail(newValue);
  }
});

// 监听email变化，同步到username字段和共享存储
watch(() => emailFormData.email, (newValue) => {
  if (newValue) {
    if (currentMethod.value === 'account') {
      formData.username = newValue;
    }
    sharedStore.setEmail(newValue);
  }
});

// 监听登录方式变化，同步邮箱字段
watch(() => currentMethod.value, (newValue) => {
  if (newValue === 'account' && emailFormData.email) {
    formData.username = emailFormData.email;
  } else if (newValue === 'email' && formData.username && formData.username.includes('@')) {
    emailFormData.email = formData.username;
  }
});

// 监听登录方式变化，重置验证组件
watch(() => currentMethod.value, () => {
  // 延迟一下，等待组件渲染完成
  setTimeout(() => {
    if (currentMethod.value === 'account') {
      accountTurnstileRef.value?.reset();
    } else if (currentMethod.value === 'email') {
      emailTurnstileRef.value?.reset();
    }
  }, 100);
});

const handleClearUsername = () => {
  // 无论用户名是什么格式，都清除共享存储
  sharedStore.clearEmail();
  // 同步清除邮箱登录方式的邮箱
  emailFormData.email = '';
};

const handleClearEmail = () => {
  // 清除共享存储
  sharedStore.clearEmail();
  // 同步清除账号密码登录方式的邮箱
  if (formData.username.includes('@')) {
    formData.username = '';
  }
};
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: var(--td-bg-color-container);
  overflow: hidden;
  padding: 0 1rem 1rem;
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--td-bg-color-container);
  z-index: 9999;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: var(--el-color-primary);
  animation: spin 1s ease-in-out infinite;
  margin-bottom: 16px;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.logo-container {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(1.5);
  transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
  z-index: 1;
  width: 100%;
  text-align: center;
}

.logo-container.animation-complete {
  position: absolute;
  top: 5vh;
  left: 50%;
  transform: translate(-50%, 0) scale(0.55);
  width: 100%;
  text-align: center;

  @media screen and (max-width: 480px) {
    top: 3vh;
    transform: translate(-50%, 0) scale(0.5);
  }
}

.logo {
  width: 150px;
  height: 150px;
  object-fit: contain;
  border-radius: 24px;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.login-box {
  width: min(90%, 400px);
  padding: min(5vmin, 32px);
  background: var(--el-bg-color);
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(30px);
  transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  transition-delay: 0.2s;
  position: relative;
  z-index: 2;
  border: 1px solid var(--el-border-color-light);
  margin: 0 auto;
  margin-top: 20vh;
  margin-bottom: 2rem;

  @media screen and (max-height: 700px) {
    margin-top: 15vh;
  }
}

.login-box.show {
  opacity: 1;
  transform: translateY(0);
}

.login-header {
  text-align: center;
  position: relative;
  margin-bottom: min(5vh, 2rem);
}

.content-wrapper {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  transition-delay: 0.5s;
  /* padding-top: 55px; */
}

.content-wrapper.show {
  opacity: 1;
  transform: translateY(0);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.login-tabs {
  display: flex;
  justify-content: center;
  margin-top: min(3vh, 1rem);
  border-bottom: 1px solid var(--td-component-stroke);
}

.tab {
  padding: min(2vmin, 8px) min(4vmin, 16px);
  cursor: pointer;
  margin: 0 min(2vmin, 8px);
  border-bottom: 2px solid transparent;
  color: var(--el-text-color-regular);
}

.tab.active {
  color: var(--el-color-primary);
  border-bottom-color: var(--el-color-primary);
}

.qrcode-container {
  text-align: center;
  padding: 24px 0;
}

.qrcode-box {
  margin-bottom: 16px;
}

.qrcode-box img {
  width: 200px;
  height: 200px;
}

.third-party-login {
  margin-top: min(5vh, 2rem);
}

.divider {
  display: flex;
  align-items: center;
  margin: min(3vh, 1rem) 0;
}

.divider::before,
.divider::after {
  content: '';
  flex: 1;
  height: 1px;
  background: var(--td-component-stroke);
}

.divider span {
  padding: 0 16px;
  color: var(--td-text-color-secondary);
  font-size: 14px;
}

.third-party-icons {
  display: flex;
  justify-content: center;
  gap: min(6vmin, 24px);
  margin-top: min(3vh, 1rem);
}

.third-party-icons :deep(.t-icon) {
  font-size: 24px;
  cursor: pointer;
  color: var(--td-text-color-secondary);
  padding: 8px;
  border-radius: 30%;
  transition: all 0.3s;
}

.third-party-icons :deep(.t-icon:hover) {
  background: var(--el-color-primary-light-9);
  color: var(--el-color-primary);
  transform: translateY(-2px);
}

.action-buttons {
  margin-top: min(5vh, 2rem);
}

.helper-links {
  display: flex;
  justify-content: space-between;
  margin-top: min(3vh, 1rem);
  flex-wrap: wrap;
  gap: 0.5rem 1rem;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: center;
  }
}

.t-form-item {
  margin-bottom: 20px;
}

:deep(.t-form__label) {
  font-size: 14px;
  color: var(--td-text-color-primary);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.fade-enter-from {
  opacity: 0;
  transform: translateX(20px);
}

.fade-leave-to {
  opacity: 0;
  transform: translateX(-20px);
}

.tab {
  position: relative;
  transition: color 0.3s ease;
}

.tab::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--td-brand-color);
  transform: scaleX(0);
  transition: transform 0.3s ease;
  transform-origin: center;
}

.tab.active::after {
  transform: scaleX(1);
}

.t-button {
  transition: all 0.3s ease;
}

.t-button:not(:disabled):hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.t-button:not(:disabled):active {
  transform: translateY(0);
}

.third-party-icons .t-icon {
  transition: all 0.3s ease;
}

.third-party-icons .t-icon:hover {
  transform: scale(1.1);
}

:deep(.t-input) {
  transition: all 0.3s ease;
}

:deep(.t-input:focus-within) {
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.login-box {
  animation: none;
}

.t-form-item {
  transition: all 0.3s ease;
  opacity: 0;
  transform: translateY(10px);
}

.login-content.show .t-form-item {
  opacity: 1;
  transform: translateY(0);
  transition-delay: calc(0.1s * var(--index, 0));
}

.t-form-item:nth-child(1) { --index: 1; }
.t-form-item:nth-child(2) { --index: 2; }
.t-form-item:nth-child(3) { --index: 3; }

.verification-code .t-button {
  transition: all 0.3s ease;
}

.verification-code .t-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

:deep(.t-form__controls-content) {
  position: relative;
}

:deep(.t-input--error) {
  animation: shake 0.5s ease;
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  75% { transform: translateX(5px); }
}

h2 {
  margin: 0;
  padding: 0;
  font-size: 24px;
  line-height: 1.4;
  position: relative;
  z-index: 11;
}

.verification-code {
  display: flex;
  gap: 8px;
  align-items: center;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    width: 100%;
  }

  .el-button {
    @media screen and (max-width: 480px) {
      width: 100%;
      margin-top: 0.5rem;
    }
  }
}

.verification-code .t-input,
.verification-code .el-input {
  flex: 1;
}

.verification-code .t-button,
.verification-code .el-button {
  white-space: nowrap;
  min-width: 100px;
}

.third-party-icons .el-icon {
  font-size: 24px;
  padding: 8px;
  border-radius: 50%;
  transition: all 0.3s;
}

.third-party-icons .el-icon:hover {
  background: var(--el-color-primary-light-9);
  color: var(--el-color-primary);
  transform: translateY(-2px);
}

:deep(.el-form-item__label) {
  font-weight: 500;
}

:deep(.el-input__wrapper) {
  box-shadow: none;
  border: 1px solid var(--el-border-color);
}

:deep(.el-input__wrapper:hover) {
  border-color: var(--el-color-primary);
}

:deep(.el-input__wrapper.is-focus) {
  border-color: var(--el-color-primary);
  box-shadow: 0 0 0 1px var(--el-color-primary-light-5);
}

.login-form {
  width: 100%;
}

:deep(.el-form) {
  width: 100%;
}

:deep(.el-form-item) {
  margin-bottom: min(3vh, 1.25rem);
  flex-direction: column;
  align-items: flex-start;
}

:deep(.el-form-item__label) {
  padding: 0 0 8px;
  line-height: 1;
  font-weight: 500;
}

:deep(.el-form-item__content) {
  display: block;
  width: 100%;
  margin-left: 0 !important;
}

:deep(.el-input) {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

:deep(.el-input__suffix) {
  cursor: pointer;
}

:deep(.el-input__suffix:hover) {
  color: var(--el-color-primary);
}

.system-title {
  position: relative;
  width: 100%;
  text-align: center;
  font-size: min(8vmin, 46px);
  color: var(--el-text-color-primary);
  margin-top: 1rem;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  transition-delay: 0.3s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  
  @media screen and (max-width: 480px) {
    font-size: max(36px, 7vmin);
    font-weight: 500;
  }
}

.system-title.show {
  opacity: 1;
  transform: translateY(0);
}
</style>