import { defineStore } from 'pinia';

// 从localStorage获取保存的邮箱
const getSavedEmail = (): string => {
  try {
    return localStorage.getItem('auth_email') || '';
  } catch (e) {
    return '';
  }
};

interface SharedState {
  email: string;
}

export const useSharedStore = defineStore('shared', {
  state: (): SharedState => ({
    email: getSavedEmail(),
  }),
  
  actions: {
    setEmail(email: string) {
      this.email = email;
      // 保存到localStorage
      try {
        localStorage.setItem('auth_email', email);
      } catch (e) {
        console.error('保存邮箱到本地存储失败', e);
      }
    },
    
    clearEmail() {
      this.email = '';
      // 从localStorage中移除
      try {
        localStorage.removeItem('auth_email');
      } catch (e) {
        console.error('从本地存储移除邮箱失败', e);
      }
    }
  }
}); 