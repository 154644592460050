import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "turnstile-container" }

import { ref, watch, onMounted } from 'vue';
import VueTurnstile from 'vue-turnstile';
import { ElMessage } from 'element-plus';

const siteKey = '0x4AAAAAABAQWW1MK2ukyat6';

export default /*@__PURE__*/_defineComponent({
  __name: 'TurnstileComponent',
  props: {
  onSuccess: {
    type: Function,
    required: false,
    default: () => {
      // 空函数
      return undefined;
    }
  }
},
  emits: ['token-change'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;

// Cloudflare Turnstile site key
const token = ref('');
const turnstileRef = ref<InstanceType<typeof VueTurnstile> | null>(null);

// 监听token变化
watch(token, (newToken) => {
  if (newToken) {
    emit('token-change', newToken);
    if (props.onSuccess) {
      props.onSuccess(newToken);
    }
  }
});

// 处理错误
const handleError = (errorCode: string) => {
  console.error('Turnstile error:', errorCode);
  ElMessage.error('验证失败，请重试');
  resetTurnstile();
};

// 处理过期
const handleExpired = () => {
  console.warn('Turnstile token expired');
  resetTurnstile();
};

// 重置Turnstile
const resetTurnstile = () => {
  if (turnstileRef.value) {
    turnstileRef.value.reset();
  }
};

// 导出重置方法，供父组件调用
__expose({
  reset: resetTurnstile,
  getToken: () => token.value
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(VueTurnstile), {
      "site-key": siteKey,
      modelValue: token.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((token).value = $event)),
      onError: handleError,
      onExpired: handleExpired,
      ref_key: "turnstileRef",
      ref: turnstileRef
    }, null, 8, ["modelValue"])
  ]))
}
}

})